export default {
  setSuccess(state, payload) {
    state.success = payload;
    state.error = !payload;
  },
  setUploadLoadingStatus(state, payload) {
    state.uploadLoading = payload;
  },
  setShowModal(state) {
    state.showModal = !state.showModal;
  },
  setModalMessage(state, message) {
    state.modalMessage = message;
  },
  resetModalContents(state) {
    state.error = false;
    state.modalMessage = null;
    state.upsPaperlessCreateErrorFlag = false;
    state.upsPaperlessDeleteErrorFlag = false;
  },
  setErrorMessage(state, error) {
    state.error = true;
    state.showModal = true;
    state.modalMessage =
      error.response && error.response.data && (error.response.data.message || error.response.data.errors)
        ? `${error.response.data.message || Object.values(error.response.data.errors).join('\n ')} `
        : error.message || error;
    state.modalConfirmBtnLabel = 'OK';
    // throw error;
  },
  setUpsPaperlessCreateErrorFlag(state, upsPaperlessCreateErrorFlag) {
    state.upsPaperlessCreateErrorFlag = upsPaperlessCreateErrorFlag;
  },
  setUpsPaperlessDeleteErrorFlag(state, upsPaperlessDeleteErrorFlag) {
    state.upsPaperlessDeleteErrorFlag = upsPaperlessDeleteErrorFlag;
  },
  setUpsPreRequestBody(state, upsPreRequestBody) {
    state.upsPreRequestBody = upsPreRequestBody;
  },
};
