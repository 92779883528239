export default {
  success(state) {
    return state.success;
  },
  error(state) {
    return state.error;
  },
  loading(state) {
    return state.loading;
  },
  uploadLoading(state) {
    return state.uploadLoading;
  },
  showModal(state) {
    return state.showModal;
  },
  modalMessage(state) {
    return state.modalMessage;
  },
  upsPaperlessCreateErrorFlag(state) {
    return state.upsPaperlessCreateErrorFlag;
  },
  upsPaperlessDeleteErrorFlag(state) {
    return state.upsPaperlessDeleteErrorFlag;
  },
  upsPreRequestBody(state) {
    return state.upsPreRequestBody;
  },
};
