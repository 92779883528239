export default {
  setSuccess(store, successful) {
    store.commit('setSuccess', successful);
  },
  setUploadLoadingStatus(store, status) {
    store.commit('setUploadLoadingStatus', status);
  },
  toggleShowModal(store) {
    store.commit('setShowModal');
  },
  setModalMessage(store, message) {
    store.commit('setModalMessage', message);
  },
  resetModalContents(store) {
    store.commit('resetModalContents');
  },
  setUpsPaperlessCreateErrorFlag(store, upsPaperlessCreateErrorFlag) {
    store.commit('setUpsPaperlessCreateErrorFlag', upsPaperlessCreateErrorFlag);
  },
  setUpsPaperlessDeleteErrorFlag(store, upsPaperlessDeleteErrorFlag) {
    store.commit('setUpsPaperlessDeleteErrorFlag', upsPaperlessDeleteErrorFlag);
  },
  setUpsPreRequestBody(store, upsPreRequestBody) {
    store.commit('setUpsPreRequestBody', upsPreRequestBody);
  },
};
