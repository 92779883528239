import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      uploadLoading: false,
      success: false,
      error: false,
      showModal: false,
      modalMessage: '',
      upsPaperlessCreateErrorFlag: false,
      upsPaperlessDeleteErrorFlag: false,
      upsPreRequestBody: {},
    };
  },
  mutations,
  actions,
  getters,
};
