import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      loading: false,
      uploadLoading: false,
      success: false,
      error: false,
      showModal: false,
      showCancelBtn: true,
      clickedConfirm: false,
      mode: '',
      modalTitle: '',
      modalMessage: 'Message',
      modalSuccessBtnLabel: 'OK',
      modalConfirmBtnLabel: 'OK',
      modalCancelBtnLabel: 'キャンセル',
      httpCode: null,
      showInstruction: false,
      showCourierRule: false,
      sort: {},
    };
  },
  mutations,
  actions,
  getters,
};
